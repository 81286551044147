
body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.slimscroll::-webkit-scrollbar {
  height: 4px;
  width: 6px;
  border: 1px solid #d5d5d5;
  
}

/* Track */
.slimscroll::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: #ece0ff;
}

/* Handle */
.slimscroll::-webkit-scrollbar-thumb {
  background: #c5a8f1;
}

/* Handle on hover */
.slimscroll::-webkit-scrollbar-thumb:hover {
  background: #8555ce;
}

.noselect {}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  
}


.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #683fb5;
  border-color: #683fb5 transparent #683fb5 transparent;
  animation: lds-hourglass 1.2s infinite;
}


div.mdl-progress::after {
  display: block;
  color: #8555ce;
  content: "Authenticating";
  font-size: 20px;
  margin: 20px auto;
  text-align: center;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.hoverparent .hidetillhover {
  opacity:0;
}

.hoverparent:hover .hidetillhover {
  opacity:1;
}

.picker-dialog {
  z-index: 2000 !important;
}


.table::-webkit-scrollbar {
  height: 2px;
  width: 4px;
  border: 1px solid #d5d5d5;
}


.MuiPaper-root ::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  border: 1px solid #d5d5d5;
}

.MuiPaper-root ::-webkit-scrollbar-track {
  background: #fffff1;
}

.MuiPaper-root ::-webkit-scrollbar-thumb {
  background: #888;
}

